const envObject = {
  //local
  // serviceUrl: 'http://localhost:2226/api',
  // serviceUrl1: 'http://localhost:2226/api',

  //dev
  // serviceUrl: " https://api.qa.audiologicpro.com/api",
  // serviceUrl1: "https://api.qa.audiologicpro.com/api",

  //production
  serviceUrl: " https://api.clinic.audiologicpro.com/api",
  serviceUrl1: "https://api.clinic.audiologicpro.com/api",
};  
 
export const environment = { 
  production: false, 
  serviceUrl: envObject.serviceUrl,
  serviceUrl1: envObject.serviceUrl1  
}
